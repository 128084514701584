import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'About',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () =>
      import(/* webpackChunkName: "contact" */ '../views/Contact.vue')
  },
  {
    path: '/downloads',
    name: 'Downloads',
    component: () =>
      import(/* webpackChunkName: "downloads" */ '../views/Downloads.vue')
  },
  {
    path: '/source1',
    name: 'Source1',
    component: () =>
      import(/* webpackChunkName: "source1" */ '../views/source1.vue')
  },
  {
    path: '/source2',
    name: 'Source2',
    component: () =>
      import(/* webpackChunkName: "source2" */ '../views/source2.vue')
  },
  {
    path: '/modelling',
    name: 'Modelling',
    component: () =>
      import(/* webpackChunkName: "modelling" */ '../views/Modelling.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
