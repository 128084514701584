var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{attrs:{"id":"nav"}},[_c('div',{staticClass:"title"},[_c('router-link',{staticStyle:{"color":"#fafafa","text-decoration":"none"},attrs:{"to":"/"}},[_c('h1',[_vm._v("Whiskee")])])],1),(_vm.windowWidth > 1000)?_c('div',{staticClass:"actions"},_vm._l((_vm.links),function(ref){
var name = ref.name;
var label = ref.label;
var icon = ref.icon;
var title = ref.title;
var link = ref.link;
return _c('router-link',{key:("nav-" + (name || link)),class:{ active: _vm.$route.name === name },attrs:{"to":{ name: name },"title":title},nativeOn:{"click":function($event){link ? _vm.openLink(link) : _vm.closeNav()}}},[(label)?_c('div',[_vm._v(" "+_vm._s(label)+" ")]):_vm._e(),_c('div',{staticStyle:{"padding":"0 4px"}},[(icon)?_c('i',{class:icon}):_vm._e()])])}),1):_c('div',{staticClass:"actions"},[_c('button',{on:{"click":function($event){_vm.expandNav = !_vm.expandNav}}},[_c('i',{staticClass:"fas fa-bars"})])])]),_c('div',{attrs:{"id":"main"}},[_c('router-view'),_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"actions center",staticStyle:{"padding-bottom":"32px"}},_vm._l((_vm.links),function(ref){
var name = ref.name;
var label = ref.label;
var icon = ref.icon;
var title = ref.title;
var link = ref.link;
return _c('router-link',{key:("footerNav-" + (name || link)),class:{ active: _vm.$route.name === name },attrs:{"to":{ name: name },"title":title},nativeOn:{"click":function($event){link ? _vm.openLink(link) : _vm.closeNav()}}},[(label)?_c('div',[_vm._v(" "+_vm._s(label)+" ")]):_vm._e(),_c('div',{staticStyle:{"padding":"0 4px"}},[(icon)?_c('i',{class:icon}):_vm._e()])])}),1)])],1),(_vm.expandNav)?_c('div',{staticClass:"navAcordian"},_vm._l((_vm.links),function(ref){
var name = ref.name;
var label = ref.label;
var icon = ref.icon;
var title = ref.title;
var link = ref.link;
return _c('router-link',{key:("dropdownNav-" + (name || link)),class:{ active: _vm.$route.name === name },attrs:{"to":{ name: name },"title":title},nativeOn:{"click":function($event){link ? _vm.openLink(link) : _vm.closeNav()}}},[(label)?_c('div',[_vm._v(" "+_vm._s(label)+" ")]):_vm._e(),_c('div',{staticStyle:{"padding":"0 4px"}},[(icon)?_c('i',{class:icon}):_vm._e()])])}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }