<template>
  <div id="app">
    <div id="nav">
      <div class="title">
        <router-link to="/" style="color: #fafafa; text-decoration: none">
          <h1>Whiskee</h1>
        </router-link>
      </div>
      <div v-if="windowWidth > 1000" class="actions">
        <router-link
          v-for="{ name, label, icon, title, link } in links"
          :key="`nav-${name || link}`"
          :class="{ active: $route.name === name }"
          :to="{ name }"
          :title="title"
          @click.native="link ? openLink(link) : closeNav()"
        >
          <div v-if="label">
            {{ label }}
          </div>
          <div style="padding: 0 4px;">
            <i v-if="icon" :class="icon" />
          </div>
        </router-link>
      </div>
      <div v-else class="actions">
        <button @click="expandNav = !expandNav">
          <i class="fas fa-bars" />
        </button>
      </div>
    </div>
    <div id="main">
      <router-view />
      <div style="position: relative;">
        <div class="actions center" style="padding-bottom: 32px;">
          <router-link
            v-for="{ name, label, icon, title, link } in links"
            :key="`footerNav-${name || link}`"
            :class="{ active: $route.name === name }"
            :to="{ name }"
            :title="title"
            @click.native="link ? openLink(link) : closeNav()"
          >
            <div v-if="label">
              {{ label }}
            </div>
            <div style="padding: 0 4px;">
              <i v-if="icon" :class="icon" />
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div v-if="expandNav" class="navAcordian">
      <router-link
        v-for="{ name, label, icon, title, link } in links"
        :key="`dropdownNav-${name || link}`"
        :class="{ active: $route.name === name }"
        :to="{ name }"
        :title="title"
        @click.native="link ? openLink(link) : closeNav()"
      >
        <div v-if="label">
          {{ label }}
        </div>
        <div style="padding: 0 4px;">
          <i v-if="icon" :class="icon" />
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      expandNav: false,
      windowWidth: window.innerWidth,
      links: [
        { name: 'About', label: 'About Me' },
        { name: 'Source1', label: 'Source 1' },
        { name: 'Source2', label: 'Source 2' },
        { name: 'Modelling', label: '3D Modelling' },
        { name: 'Downloads', label: 'Downloads' },
        { name: 'Contact', label: 'Contact' },
        { icon: 'fab fa-lg fa-github', title: 'Github', link: 'https://github.com/WhiskeeDev' },
        { icon: 'fab fa-lg fa-discord', title: 'Discord', link: 'https://discord.com/invite/APtzaJQ' },
        { icon: 'fab fa-lg fa-youtube', title: 'Youtube', link: 'https://www.youtube.com/channel/UCA0pDV1anSw9uDV559a27sA' },
        { icon: 'fab fa-lg fa-steam', title: 'Steam', link: 'https://steamcommunity.com/id/LiamHolmes/' },
        { icon: 'fab fa-lg fa-twitch', title: 'Twitch', link: 'https://www.twitch.tv/whiskee0001' },
        { icon: 'fab fa-lg fa-twitter', title: 'Twitter', link: 'https://twitter.com/WhiskeeDev' }
      ]
    }
  },
  watch: {
    windowWidth (val) {
      if (this.expandNav && val >= 1000) this.expandNav = false
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    openLink (link) {
      if (!link) return false
      window.open(link, '_blank')
      this.closeNav()
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    closeNav () { this.expandNav = false }
  }
}
</script>
